import { Box } from "@mui/material";
import Navbar from "./components/Navbar";
import useStore from '../../store/useStore';



const Header = () => {
    const bgColor = useStore((state) => state.bgColor);
    const img = useStore((state) => state.img);
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="end"
            alignItems="center"
            color="white"
            minHeight="352px"
            sx={{
                backgroundImage: `url(${img})`,
                backgroundSize: "cover",
                backgroundPosition: "center center"
            }}
        >
            <Navbar />
        </Box>
    )
}

export default Header;