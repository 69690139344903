import { Box, Container, Link } from "@mui/material";
import ThemeSwitcher from '../ThemeSwitcher';
import { Twitter, Instagram } from "@mui/icons-material";
import TwitchIcon from "../../../../components/TwitchIcon";
import DiscordIcon from "../../../../components/DiscordIcon";
import './Navbar.scss';

const Navbar = () => (
    <Container maxWidth="xl">
        <Box
            display="flex"
            justifyContent="space-between"

        >
            <ThemeSwitcher />
            <a href="#main"><div className="icon-scroll"></div></a>
            <Box>
                <Link 
                    href="https://www.twitch.tv/yunatahel"
                    target="_blank" 
                    underline="none" 
                    color="inherit" 
                    sx={{
                        marginLeft:2,
                        "&:hover": {
                            color: "#9146ff",
                        }
                    }}
                >
                    <TwitchIcon />
                </Link>
                <Link 
                    href="https://twitter.com/Yunatahel"
                    target="_blank" 
                    underline="none" 
                    color="inherit" 
                    sx={{
                        marginLeft:2,
                        "&:hover": {
                            color: "#1da1f2",
                        }
                    }}
                >
                    <Twitter />
                </Link>
                <Link 
                    href="https://discord.gg/fc4VD2zZ34" 
                    target="_blank" 
                    underline="none" 
                    color="inherit" 
                    sx={{
                        marginLeft:2,
                        "&:hover": {
                            color: "#5562ea",
                        }
                    }}
                >
                    <DiscordIcon />
                </Link>
                <Link 
                    href="https://www.instagram.com/yunatahel/" 
                    target="_blank" 
                    underline="none" 
                    color="inherit" 
                    sx={{
                        marginLeft:2,
                        "&:hover": {
                            color: "#d94270",
                        }
                    }}
                >
                    <Instagram />
                </Link>
            </Box>
        
        </Box>
    </Container>
);

export default Navbar;