import { createTheme } from '@mui/material';

const generateTheme = (mode) => {
    const theme = {
        breakpoints: {
            keys: ['xs', 'sm', 'md', 'lg', 'xl'],
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
            },
        },
        typography: {
            fontFamily: [
                'Assistant',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
                ].join(','),
          },
        palette: {
            mode: mode,
            primary: {
                light: '#fdc771',
                main: '#fdba4e',
                dark: '#b18236',
                contrastText: '#fff',
            },
            ...(mode === 'light') ? {
                text: {
                    primary: "#212529",
                }
            } : {

            }
        },
    };
    
    return createTheme(theme);
}

export default generateTheme;