import Router from './router/AppRouter';
import { useMemo } from 'react';
import useStore from './store/useStore';
import generateTheme from './theme';
import { StyledEngineProvider, ThemeProvider, CssBaseline } from '@mui/material';

function App() {
  const ThemeSelected = useStore((state) => state.theme);
  const theme = useMemo(
    () => generateTheme(ThemeSelected),
    [ThemeSelected]
  );
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
