import { Box, Container, Typography } from "@mui/material";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import Header from "../Header";
import useStore from "../../store/useStore";
import "./Layout.scss";

const Layout = () => {
  const changeLayout = useStore((state) => state.changeLayout);
  const lastPage = useStore((state) => state.lastPage);

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      position="relative"
    >
      <Header />
      <Box id="main">
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={3}
          >
            <ul className="events-nav">
              <li className={lastPage === "voila" ? "active" : null}>
                <Link
                  to="/"
                  onClick={() => {
                    changeLayout("voila");
                  }}
                >
                  <Typography
                    variant="h5"
                    component="span"
                    sx={{ color: "text.primary" }}
                  >
                    Voila hiver
                  </Typography>
                </Link>
              </li>
              <li className={lastPage === "meltdown" ? "active" : null}>
                <Link
                  to="/meltdown"
                  onClick={() => {
                    changeLayout("meltdown");
                  }}
                  underline="none"
                >
                  <Typography
                    variant="h5"
                    component="span"
                    sx={{ color: "text.primary" }}
                  >
                    Meltdown
                  </Typography>
                </Link>
              </li>
              <li className={lastPage === "shooting" ? "active" : null}>
                <Link
                  to="/shooting"
                  onClick={() => {
                    changeLayout("shooting");
                  }}
                  underline="none"
                >
                  <Typography
                    variant="h5"
                    component="span"
                    sx={{ color: "text.primary" }}
                  >
                    Shooting Medivh/Irion
                  </Typography>
                </Link>
              </li>
            </ul>
          </Box>
          <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </Suspense>
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;
