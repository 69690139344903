import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '../layout/Layout';

const VoilaHiver = lazy(() => import('../pages/VoilaHiver'));
const Meltdown = lazy(() => import('../pages/Meltdown'));
const Shooting = lazy(() => import('../pages/Shooting'));

const AllRoutes = () => (
    <Routes>
        <Route element={<Layout />}>
            <Route index element={<VoilaHiver />} />
            <Route path="meltdown" element={<Meltdown />} />
            <Route path="shooting" element={<Shooting />} />
        </Route>
    </Routes>
);

export default AllRoutes;