import create from 'zustand';

import VoilaHiverImage from '../assets/img/voila-hiver-banniere.png';
import MeltdownImage from '../assets/img/meltdown-banniere.png';
import ShootingImage from '../assets/img/shooting-banniere.png';

const initalTheme = localStorage.getItem('theme') !== null ? localStorage.getItem('theme') : window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
const initialLastPage = localStorage.getItem('lastPage')!== null ? localStorage.getItem('lastPage') : 'voila';

const pageParameters = {
    voila: {
        bgColor: '#510100',
        img: VoilaHiverImage
    },
    meltdown: {
        bgColor: '#090b0d',
        img: MeltdownImage
    },
    shooting: {
        bgColor: '#08609E',
        img: ShootingImage
    }
}

const useStore = create(set => ({
    theme: initalTheme,
    bgColor: pageParameters[initialLastPage].bgColor,
    img: pageParameters[initialLastPage].img,
    lastPage: initialLastPage,
    changeTheme: () => set((state) => {
        localStorage.setItem('theme', state.theme === "light"? "dark" : "light");
        return {
            ...state, 
            theme: state.theme === "light"? "dark" : "light"
        };
    }),
    changeLayout: (page) => set((state) => { 
        localStorage.setItem('lastPage', page);
        return { 
            ...state, 
            lastPage: page,
            bgColor: pageParameters[page].bgColor,
            img: pageParameters[page].img
        };
    }),
}))

export default useStore;