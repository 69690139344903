import { Box, IconButton } from "@mui/material";
import { Brightness4, Brightness7 } from "@mui/icons-material";

import useStore from '../../../../store/useStore';

const ThemeSwitcher = () => {
    const theme = useStore((state) => state.theme);
    const changeTheme = useStore((state) => state.changeTheme);

    return (
        <Box>
        <IconButton onClick={changeTheme} color="inherit">
            {theme === 'dark' ? <Brightness7 /> : <Brightness4 />}
        </IconButton>
    </Box>
    )
    
};

export default ThemeSwitcher;